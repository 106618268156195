import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  symbols: [],
  isSymbolsLoading: false,
  errorSymbols: null,
  newsSymbols: [],
  isNewsSymbolsLoading: false,
  errorNewsSymbols: null,
  venues: [],
  isVenuesLoading: false,
  errorVenues: null,
  regions: [],
  isRegionsLoading: [],
  errorRegions: null,
  industries: [],
  isIndustriesLoading: [],
  errorIndustries: null,
  selectedCountry: "Canada",
};

// FUNCTIONS TO FETCH MARKET DATA 

export const fetchSymbols = createAsyncThunk(
  "domain/fetchSymbols",
  async (args, thunkAPI, other) => {
    try {

      const state = thunkAPI.getState();
      const selectedCountry = state.domain.selectedCountry;

      // Determine which file to fetch based on the selected country
      const symbolsFile = selectedCountry === "Canada" ? "./symbols.csv" : "./symbols-us.csv";

      const response = await fetch(symbolsFile);

      console.log("domainSlice->fetchSymbols->raw response data", response);

      const csv = await response.text();
      const symbols = csv.split('\n').slice(1).map(row => row.slice(1, -1)); //Skip header and remove double quotes
      return symbols;
    } catch (error) {
      console.log("fetchSymbols ThunkAPI error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchNewsSymbols = createAsyncThunk(
  "domain/fetchNewsSymbols",
  async (args, thunkAPI, other) => {
    try {
      const response = await fetch("./news-symbols-no-suffix.csv");

      console.log("domainSlice->fetchNewsSymbols->raw response data", response);

      const csv = await response.text();
      const symbols = csv.split('\n').slice(1); //Skip header

      return symbols;
    } catch (error) {
      console.log("fetchNewsSymbols ThunkAPI error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const fetchVenues = createAsyncThunk(
  "domain/fetchVenues",
  async (args, thunkAPI, other) => {
    try {
      const state = thunkAPI.getState();
      const selectedCountry = state.domain.selectedCountry;

      // Determine which file to fetch based on the selected country
      const venueFile = selectedCountry === "Canada" ? "./venues.json" : "./venues-us.json";

      const response = await fetch(venueFile);
      console.log("domainSlice->fetchVenues->raw response data", response);

      const json = await response.text();
      return JSON.parse(json);
    } catch (error) {
      console.log("fetchVenues ThunkAPI error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const fetchRegions = createAsyncThunk(
  "domain/fetchRegions",
  async (args, thunkAPI, other) => {
    try {
      const response = await fetch("./regions.json");
      console.log("domainSlice->fetchRegions->raw response data", response);

      const json = await response.text();
      return JSON.parse(json);
    } catch (error) {
      console.log("fetchRegions ThunkAPI error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchIndustries = createAsyncThunk(
  "domain/fetchIndustries",
  async (args, thunkAPI, other) => {
    try {
      const response = await fetch("./industries.json");
      console.log("domainSlice->fetchIndustries->raw response data", response);

      const json = await response.text();
      return JSON.parse(json);
    } catch (error) {
      console.log("fetchIndustries ThunkAPI error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// SLICE
export const domainSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {
        // Action to update the selected country
        updateSelectedCountry: (state, action) => {
          state.selectedCountry = action.payload;
        },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSymbols.pending, (state) => {
        state.isSymbolsLoading = true;
      })
      .addCase(fetchSymbols.fulfilled, (state, action) => {
        console.log("fetchSymbols.fulfilled", action);
        state.symbols = action.payload;
        state.isSymbolsLoading = false;
      })
      .addCase(fetchSymbols.rejected, (state, action) => {
        console.log(
          "ERROR fetchSymbols.rejected",
          action.payload.response.status
        );
        state.isSymbolsLoading = false;
        state.errorSymbols = action.error.message;
      })

      .addCase(fetchNewsSymbols.pending, (state) => {
        state.isNewsSymbolsLoading = true;
      })
      .addCase(fetchNewsSymbols.fulfilled, (state, action) => {
        console.log("fetchNewsSymbols.fulfilled", action);
        state.newsSymbols = action.payload;
        state.isNewsSymbolsLoading = false;
      })
      .addCase(fetchNewsSymbols.rejected, (state, action) => {
        console.log(
          "ERROR fetchNewsSymbols.rejected",
          action.payload.response.status
        );
        state.isNewsSymbolsLoading = false;
        state.errorNewsSymbols = action.error.message;
      })

      .addCase(fetchVenues.pending, (state) => {
        state.isVenuesLoading = true;
      })
      .addCase(fetchVenues.fulfilled, (state, action) => {
        console.log("fetchVenues.fulfilled", action);
        state.venues = action.payload;
        state.isVenuesLoading = false;
      })
      .addCase(fetchVenues.rejected, (state, action) => {
        console.log(
          "ERROR fetchVenues.rejected",
          action.payload.response.status
        );
        state.isVenuesLoading = false;
        state.errorVenues = action.error.message;
      })

      .addCase(fetchRegions.pending, (state) => {
        state.isRegionsLoading = true;
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        console.log("fetchRegions.fulfilled", action);
        state.regions = action.payload;
        state.isRegionsLoading = false;
      })
      .addCase(fetchRegions.rejected, (state, action) => {
        console.log(
          "ERROR fetchRegions.rejected",
          action.payload.response.status
        );
        state.isRegionsLoading = false;
        state.errorRegions = action.error.message;
      })
      .addCase(fetchIndustries.pending, (state) => {
        state.isIndustriesLoading = true;
      })
      .addCase(fetchIndustries.fulfilled, (state, action) => {
        console.log("fetchIndustries.fulfilled", action);
        state.industries = action.payload;
        state.isIndustriesLoading = false;
      })
      .addCase(fetchIndustries.rejected, (state, action) => {
        console.log(
          "ERROR fetchIndustries.rejected",
          action.payload.response.status
        );
        state.isIndustriesLoading = false;
        state.errorIndustries = action.error.message;
      });
  },
});

// ACTION CREATORS
// export const {} = domain.actions;
export const { updateSelectedCountry } = domainSlice.actions;

// SELECTORS
export const selectSymbols = (state) => state.domain.symbols;
export const selectIsSymbolsLoading = (state) => state.domain.isSymbolsLoading;
export const selectNewsSymbols = (state) => state.domain.newsSymbols;
export const selectIsNewsSymbolsLoading = (state) => state.domain.isNewsSymbolsLoading;
export const selectVenues = (state) => state.domain.venues;
export const selectIsVenuesLoading = (state) => state.domain.isVenuesLoading;
export const selectRegions = (state) => state.domain.regions;
export const selectIsRegionsLoading = (state) => state.domain.isRegionsLoading;
export const selectSelectedCountry = (state) => state.domain.selectedCountry;
export const selectIndustries = (state) => state.domain.industries;
export const selectIsIndustriesLoading = (state) => state.domain.isIndustriesLoading;
// SLICE REDUCER
export const domainReducer = domainSlice.reducer;

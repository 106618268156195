import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { QMAIReportsInput, qmFetchAIReport } from "@berkindale/berkindale-provider-quotemedia-javascript-api";

export interface AIReportsFilters {
  reportType: "daily" | "industry";
  ticker: string;
  date: string;
  industry: string;
  interval: string;
}

interface AIReportResponse {
  report: any | null; 
  status: string;
}

interface AIReportsState {
  filters: AIReportsFilters;
  isLoading: boolean;
  generatedReport: string | null;
  reportError: string | null;
  dailyReportData: string | null;
  industryReportData: string | null;

}

const initialState: AIReportsState = {
  filters: {
    reportType: "daily",
    ticker: "AC\r",
    date: '',
    industry: "",
    interval: "",
  },
  isLoading: false,
  generatedReport: null,
  reportError: null,
  dailyReportData: null,
  industryReportData: null,
};

export const fetchAIReports = createAsyncThunk<
  AIReportResponse, 
  QMAIReportsInput
>("aiReports/fetchAIReports", async (input, thunkAPI) => {
  try {
    let attempts = 0;
    const maxAttempts = 50;

    while (attempts < maxAttempts) {
      const resp = await qmFetchAIReport(input);

      console.log("Response received:", resp);

      if (resp.status === 'pending') {
        console.log("Status is 202: Processing, retrying...");
        attempts++;
        await new Promise((resolve) => setTimeout(resolve, 2000));
        continue;
      }
      const data: AIReportResponse = await resp;
      return data;
    }
    throw new Error("Max retry attempts exceeded. Unable to fetch AI reports.");
  } catch (error: any) {
    console.error("fetchAIReports ThunkAPI error:", error);

    return thunkAPI.rejectWithValue(error || "Unknown error occurred");
  }
});




const aiReportsSlice = createSlice({
  name: "aiReports",
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<Partial<AIReportsFilters>>) {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setGeneratedReport(state, action: PayloadAction<string | null>) {
      state.generatedReport = action.payload;
    },
    setReportError(state, action: PayloadAction<string | null>) {
      state.reportError = action.payload;
    },
    setDailyReportData(state, action: PayloadAction<string | null>) {
      state.dailyReportData = action.payload;
    },
    setIndustryReportData(state, action: PayloadAction<string | null>) {
      state.industryReportData = action.payload;
    },
    resetFilters(state) {
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAIReports.pending, (state) => {
        state.isLoading = true;
        state.reportError = null;
      })
      .addCase(fetchAIReports.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        if (state.filters.reportType === "daily") {
          state.dailyReportData =  action.payload.report || "No report data available.";
         
        } else {
          state.industryReportData =  action.payload.report || "No report data available.";
        }
        state.generatedReport = action.payload;
      })
      .addCase(fetchAIReports.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.reportError = action.payload || "Failed to fetch AI reports";
      });
  },
});

export const selectAIReportsFilters = (state: RootState) => state.aiReports.filters;
export const selectAIReportsLoading = (state: RootState) => state.aiReports.isLoading;
export const selectGeneratedReport = (state: RootState) => state.aiReports.generatedReport;
export const selectReportError = (state: RootState) => state.aiReports.reportError;
export const selectDailyReportData = (state: RootState) => state.aiReports.dailyReportData;
export const selectIndustryReportData = (state: RootState) => state.aiReports.industryReportData;

export const { setFilters, setLoading, setGeneratedReport, setReportError, resetFilters, setDailyReportData,
  setIndustryReportData } =
  aiReportsSlice.actions;

export const aiReportReducer = aiReportsSlice.reducer;

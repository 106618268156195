// runAmplifyConfigure.ts

// This replaces the configuration generated by Amplify CLI (the CLI generated config was saved in src/aws-exports.js). Now we use the Amplify Auth libraries without using the amplify-cli. We stopped using amplify-cli because we need to create separate builds of the webapp (dev, UAT, prod), each of which uses different AWS resources (e.g. dev uses Cognito user pool abc123, while UAT uses user pool xyz789). Amplify CLI made it difficult to switch between different AWS resources without dealing with a plethora of config folders and files. Usage of Amplify CLI also inadvertently created a lot of AWS resources (IAM roles, s3 buckets etc) that were not used by Berkindale.

export const authConfig = {
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID,

  // REQUIRED - Amazon Cognito Region
  region: import.meta.env.VITE_AWS_REGION,

  // OPTIONAL - Amazon Cognito Federated Identity Pool Region
  // Required only if it's different from Amazon Cognito Region
  // identityPoolRegion: 'XX-XXXX-X',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId:
    import.meta.env.VITE_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,

  endpoint: import.meta.env.VITE_AWS_COGNITO_ENDPOINT || undefined,

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  // mandatorySignIn: false,

  // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
  // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
  // signUpVerificationMethod: 'code', // 'code' | 'link'

  // OPTIONAL - Configuration for cookie storage
  // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
  // cookieStorage: {
  // REQUIRED - Cookie domain (only required if cookieStorage is provided)
  // domain: '.yourdomain.com',
  // OPTIONAL - Cookie path
  // path: '/',
  // OPTIONAL - Cookie expiration in days
  // expires: 365,
  // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
  // sameSite: "strict" | "lax",
  // OPTIONAL - Cookie secure flag
  // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
  // secure: true
  // },

  // OPTIONAL - customized storage object
  // storage: MyStorage,

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  authenticationFlowType: import.meta.env.VITE_AWS_COGNITO_AUTH_TYPE || undefined,

  // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
  // clientMetadata: { myCustomKey: 'myCustomValue' },

  // OPTIONAL - Hosted UI configuration
  // oauth: {
  //     domain: 'your_cognito_domain',
  //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  //     redirectSignIn: 'http://localhost:3000/',
  //     redirectSignOut: 'http://localhost:3000/',
  //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  // }
};

/**
 * There are three different access levels on the storage bucket: public, protected and private.
 *
 * Public: Accessible by all users of your app.
 *         Files are stored under the public/ path in your S3 bucket.
 *
 * Protected: Readable by all users, but writable only by the creating user.
 *         Files are stored under protected/{user_identity_id}/
 *         where the user_identity_id corresponds to the unique Amazon Cognito Identity ID for that user.
 *
 * Private: Only accessible for the individual user.
 *         Files are stored under private/{user_identity_id}/
 *         where the user_identity_id corresponds to the unique Amazon Cognito Identity ID for that user.
 *
 * src: https://docs.amplify.aws/lib/storage/configureaccess/q/platform/js/
 *
 * Enable automatic tracking of Storage Events such as uploads and downloads, by setting { track: true } when calling the Storage API.
 * src: https://docs.amplify.aws/lib/storage/autotrack/q/platform/js/
 */
export const storageConfig = {
  AWSS3: {
    bucket: import.meta.env.VITE_PROFILE_PICTURE_BUCKET, // REQUIRED - Bucket name
    region: import.meta.env.VITE_AWS_REGION, // OPTIONAL
    track: true, // if set to true, automatically sends Storage Events to Amazon Pinpoint
    level: "private", // access level. read comment above
  },
};

import { qmFetchSentiment } from "@berkindale/berkindale-provider-quotemedia-javascript-api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { RootState } from "../../app/store";
import { getPreviousDay } from "../common/utils";

const maxDate = getPreviousDay(new Date(), true, false);

export interface SentimentFilter {
  industry: string;
  searchOption: string;
  symbol: string;
  region: string;
  start: number;
  end: number;
}

interface NewsOrTweetRecord {
  id: number;
  timestamp: number;
  content: string;
  sentiment: number;
  url: string;
}

export interface SentimentState {
  tickerNews: {
    [key: number]: {
      records: NewsOrTweetRecord[];
      averageSentiment: number | null;
    };
  };
  regionNews: {
    [key: number]: {
      records: NewsOrTweetRecord[];
      averageSentiment: number | null;
    };
  };
  tweets: {
    [key: number]: {
      records: NewsOrTweetRecord[];
      averageSentiment: number | null;
    };
  };
  tickerNewsLoading: boolean;
  regionNewsLoading: boolean;
  tweetsLoading: boolean;
  tickerNewsError: string | null;
  regionNewsError: string | null;
  tweetsError: string | null;
  filter: SentimentFilter;
}

const initialState: SentimentState = {
  tickerNews: {},
  regionNews: {},
  tweets: {},
  tickerNewsLoading: false,
  regionNewsLoading: false,
  tweetsLoading: false,
  tickerNewsError: null,
  regionNewsError: null,
  tweetsError: null,
  filter: {
    searchOption: "ticker",
    symbol: "AC\r",
    region: "",
    industry:'',
    start: maxDate.getTime(),
    end: maxDate.getTime(),
  },
};

export interface FetchSentimentInput {
  isNews: boolean;
  searchOption: string;
  symbol?: string | null;
  region?: string | null;
  industry?: string | null;
  jwtToken: string;
  start: number;
  end: number;
  hash: number;
}

export interface FetchSentimentResponse {
  hash: number;
  dataObj: {
    records: NewsOrTweetRecord[];
    averageSentiment: number;
  };
}

export const fetchSentiment = createAsyncThunk<
  FetchSentimentResponse,
  FetchSentimentInput
>("fetchSentiment", async (input, thunkAPI) => {
  try {
    const { isNews, searchOption, symbol, region, industry, jwtToken, hash } = input;

    const start = format(input.start, "yyyy-MM-dd");
    const end = format(input.end, "yyyy-MM-dd");

    const resp = await qmFetchSentiment({
      type: isNews ? "news" : "twitter",
      searchOption,
      symbol: symbol || undefined,
      region: region || undefined,
      industry: industry || undefined,
      start,
      end,
      jwtToken,
    });

    const records: NewsOrTweetRecord[] = resp.records;
    const averageSentiment: number = resp.averageSentiment;

    return {
      hash,
      dataObj: {
        records,
        averageSentiment,
      },
    };
  } catch (error) {
    console.log("fetchSentiment ThunkAPI error", error);
    return thunkAPI.rejectWithValue(error);
  }
});


export const sentimentSlice = createSlice({
  name: "sentiment",
  initialState,
  reducers: {
    setTickerNewsLoading: (state, action) => {
      state.tickerNewsLoading = action.payload.status;
    },
    setRegionNewsLoading: (state, action) => {
      state.regionNewsLoading = action.payload.status;
    },
    setFetchTweetsLoading: (state, action) => {
      state.tweetsLoading = action.payload.status;
    },
    updateSentimentFilter: (state, action) => {
      state.filter.symbol = action.payload.symbol;
      state.filter.region = action.payload.region;
      state.filter.industry = action.payload.industry;
      state.filter.start = action.payload.start;
      state.filter.end = action.payload.end;
    },
    updateSentimentSearchOption: (state, action) => {
      state.filter.searchOption = action.payload.option;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSentiment.pending, (state, action) => {
        const { isNews, searchOption } = action.meta.arg;
        if (isNews) {
          if (searchOption === "ticker") {
            state.tickerNewsLoading = true;
            state.tickerNewsError = null;
          } else if (searchOption === "region") {
            state.regionNewsLoading = true;
            state.regionNewsError = null;
          }
        } else {
          state.tweetsLoading = true;
          state.tweetsError = null;
        }
      })
      .addCase(fetchSentiment.fulfilled, (state, action) => {
        const { isNews, searchOption } = action.meta.arg;
        const { hash, dataObj } = action.payload;
        if (isNews) {
          if (searchOption === "ticker") {
            state.tickerNews[hash] = dataObj;
            state.tickerNewsLoading = false;
            state.tickerNewsError = null;
          } else if (searchOption === "region") {
            state.regionNews[hash] = dataObj;
            state.regionNewsLoading = false;
            state.regionNewsError = null;
          }
        } else {
          state.tweets[hash] = dataObj;
          state.tweetsLoading = false;
          state.tweetsError = null;
        }
      })
      .addCase(fetchSentiment.rejected, (state, action) => {
        const { isNews, searchOption } = action.meta.arg;
        if (isNews) {
          if (searchOption === "ticker") {
            state.tickerNewsLoading = false;
            state.tickerNewsError = action.error.message!;
          } else if (searchOption === "region") {
            state.regionNewsLoading = false;
            state.regionNewsError = action.error.message!;
          }
        } else {
          state.tweetsLoading = false;
          state.tweetsError = action.error.message!;
        }
      });
  }
});


export const selectTickerNews = (state: RootState) => state.sentiment.tickerNews;
export const selectRegionNews = (state: RootState) => state.sentiment.regionNews;
export const selectTweets = (state: RootState) => state.sentiment.tweets;

export const selectTickerNewsLoading = (state: RootState) =>
  state.sentiment.tickerNewsLoading;
export const selectRegionNewsLoading = (state: RootState) =>
  state.sentiment.regionNewsLoading;
export const selectTweetsLoading = (state: RootState) =>
  state.sentiment.tweetsLoading;

export const selectTickerNewsError = (state: RootState) =>
  state.sentiment.tickerNewsError;
export const selectRegionNewsError = (state: RootState) =>
  state.sentiment.regionNewsError;
export const selectTweetsError = (state: RootState) =>
  state.sentiment.tweetsError;

export const selectSentimentFilter = (state: RootState) =>
  state.sentiment.filter;


export const {
  setTickerNewsLoading,
  setRegionNewsLoading,
  setFetchTweetsLoading,
  updateSentimentFilter,
  updateSentimentSearchOption,
} = sentimentSlice.actions;

export const sentimentReducer = sentimentSlice.reducer;
